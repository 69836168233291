
if (import.meta.hot) {
  import.meta.hot.accept((mod) => {
    const router = import.meta.hot.data.router
    const generateRoutes = import.meta.hot.data.generateRoutes
    if (!router || !generateRoutes) {
      import.meta.hot.invalidate('[nuxt] Cannot replace routes because there is no active router. Reloading.')
      return
    }
    router.clearRoutes()
    const routes = generateRoutes(mod.default || mod)
    function addRoutes (routes) {
      for (const route of routes) {
        router.addRoute(route)
      }
      router.replace(router.currentRoute.value.fullPath)
    }
    if (routes && 'then' in routes) {
      routes.then(addRoutes)
    } else {
      addRoutes(routes)
    }
  })
}

export function handleHotUpdate(_router, _generateRoutes) {
  if (import.meta.hot) {
    import.meta.hot.data ||= {}
    import.meta.hot.data.router = _router
    import.meta.hot.data.generateRoutes = _generateRoutes
  }
}
import { default as _91_46_46_46slug_93eD0_45aLJH_SWaJVw5CBvPn8cEE2xWIMXlvr23TEyf8PMMeta } from "/vercel/path0/pages/[...slug].vue?macro=true";
import { default as component_45stuboAz8_45A0Kn4UDy3_45mbrx41goFO1bmmVjE5gsRC5joJYEMeta } from "/vercel/path0/node_modules/nuxt/dist/pages/runtime/component-stub.js?macro=true";
import { default as component_45stuboAz8_45A0Kn4UDy3_45mbrx41goFO1bmmVjE5gsRC5joJYE } from "/vercel/path0/node_modules/nuxt/dist/pages/runtime/component-stub.js";
export default [
  {
    name: "slug___de___default",
    path: "/:slug(.*)*",
    component: () => import("/vercel/path0/pages/[...slug].vue")
  },
  {
    name: "slug___de",
    path: "/de/:slug(.*)*",
    component: () => import("/vercel/path0/pages/[...slug].vue")
  },
  {
    name: "slug___en",
    path: "/en/:slug(.*)*",
    component: () => import("/vercel/path0/pages/[...slug].vue")
  },
  {
    name: component_45stuboAz8_45A0Kn4UDy3_45mbrx41goFO1bmmVjE5gsRC5joJYEMeta?.name,
    path: "/deutsch/price_ii.php",
    component: component_45stuboAz8_45A0Kn4UDy3_45mbrx41goFO1bmmVjE5gsRC5joJYE
  },
  {
    name: component_45stuboAz8_45A0Kn4UDy3_45mbrx41goFO1bmmVjE5gsRC5joJYEMeta?.name,
    path: "/english/price_ii.php",
    component: component_45stuboAz8_45A0Kn4UDy3_45mbrx41goFO1bmmVjE5gsRC5joJYE
  },
  {
    name: component_45stuboAz8_45A0Kn4UDy3_45mbrx41goFO1bmmVjE5gsRC5joJYEMeta?.name,
    path: "/deutsch/horse.php",
    component: component_45stuboAz8_45A0Kn4UDy3_45mbrx41goFO1bmmVjE5gsRC5joJYE
  },
  {
    name: component_45stuboAz8_45A0Kn4UDy3_45mbrx41goFO1bmmVjE5gsRC5joJYEMeta?.name,
    path: "/english/horse.php",
    component: component_45stuboAz8_45A0Kn4UDy3_45mbrx41goFO1bmmVjE5gsRC5joJYE
  },
  {
    name: component_45stuboAz8_45A0Kn4UDy3_45mbrx41goFO1bmmVjE5gsRC5joJYEMeta?.name,
    path: "/deutsch/about.php",
    component: component_45stuboAz8_45A0Kn4UDy3_45mbrx41goFO1bmmVjE5gsRC5joJYE
  },
  {
    name: component_45stuboAz8_45A0Kn4UDy3_45mbrx41goFO1bmmVjE5gsRC5joJYEMeta?.name,
    path: "/english/about.php",
    component: component_45stuboAz8_45A0Kn4UDy3_45mbrx41goFO1bmmVjE5gsRC5joJYE
  },
  {
    name: component_45stuboAz8_45A0Kn4UDy3_45mbrx41goFO1bmmVjE5gsRC5joJYEMeta?.name,
    path: "/deutsch/contact.php",
    component: component_45stuboAz8_45A0Kn4UDy3_45mbrx41goFO1bmmVjE5gsRC5joJYE
  },
  {
    name: component_45stuboAz8_45A0Kn4UDy3_45mbrx41goFO1bmmVjE5gsRC5joJYEMeta?.name,
    path: "/english/contact.php",
    component: component_45stuboAz8_45A0Kn4UDy3_45mbrx41goFO1bmmVjE5gsRC5joJYE
  },
  {
    name: component_45stuboAz8_45A0Kn4UDy3_45mbrx41goFO1bmmVjE5gsRC5joJYEMeta?.name,
    path: "/italiano/contact.php",
    component: component_45stuboAz8_45A0Kn4UDy3_45mbrx41goFO1bmmVjE5gsRC5joJYE
  },
  {
    name: component_45stuboAz8_45A0Kn4UDy3_45mbrx41goFO1bmmVjE5gsRC5joJYEMeta?.name,
    path: "/deutsch/trekking.php",
    component: component_45stuboAz8_45A0Kn4UDy3_45mbrx41goFO1bmmVjE5gsRC5joJYE
  },
  {
    name: component_45stuboAz8_45A0Kn4UDy3_45mbrx41goFO1bmmVjE5gsRC5joJYEMeta?.name,
    path: "/english/trekking.php",
    component: component_45stuboAz8_45A0Kn4UDy3_45mbrx41goFO1bmmVjE5gsRC5joJYE
  },
  {
    name: component_45stuboAz8_45A0Kn4UDy3_45mbrx41goFO1bmmVjE5gsRC5joJYEMeta?.name,
    path: "/deutsch/link.php",
    component: component_45stuboAz8_45A0Kn4UDy3_45mbrx41goFO1bmmVjE5gsRC5joJYE
  },
  {
    name: component_45stuboAz8_45A0Kn4UDy3_45mbrx41goFO1bmmVjE5gsRC5joJYEMeta?.name,
    path: "/english/link.php",
    component: component_45stuboAz8_45A0Kn4UDy3_45mbrx41goFO1bmmVjE5gsRC5joJYE
  },
  {
    name: component_45stuboAz8_45A0Kn4UDy3_45mbrx41goFO1bmmVjE5gsRC5joJYEMeta?.name,
    path: "/deutsch/location.php",
    component: component_45stuboAz8_45A0Kn4UDy3_45mbrx41goFO1bmmVjE5gsRC5joJYE
  },
  {
    name: component_45stuboAz8_45A0Kn4UDy3_45mbrx41goFO1bmmVjE5gsRC5joJYEMeta?.name,
    path: "/english/location.php",
    component: component_45stuboAz8_45A0Kn4UDy3_45mbrx41goFO1bmmVjE5gsRC5joJYE
  },
  {
    name: component_45stuboAz8_45A0Kn4UDy3_45mbrx41goFO1bmmVjE5gsRC5joJYEMeta?.name,
    path: "/italiano/location.php",
    component: component_45stuboAz8_45A0Kn4UDy3_45mbrx41goFO1bmmVjE5gsRC5joJYE
  },
  {
    name: component_45stuboAz8_45A0Kn4UDy3_45mbrx41goFO1bmmVjE5gsRC5joJYEMeta?.name,
    path: "/deutsch/bivignano.php",
    component: component_45stuboAz8_45A0Kn4UDy3_45mbrx41goFO1bmmVjE5gsRC5joJYE
  },
  {
    name: component_45stuboAz8_45A0Kn4UDy3_45mbrx41goFO1bmmVjE5gsRC5joJYEMeta?.name,
    path: "/english/bivignano.php",
    component: component_45stuboAz8_45A0Kn4UDy3_45mbrx41goFO1bmmVjE5gsRC5joJYE
  },
  {
    name: component_45stuboAz8_45A0Kn4UDy3_45mbrx41goFO1bmmVjE5gsRC5joJYEMeta?.name,
    path: "/italiano/bivignano.php",
    component: component_45stuboAz8_45A0Kn4UDy3_45mbrx41goFO1bmmVjE5gsRC5joJYE
  },
  {
    name: component_45stuboAz8_45A0Kn4UDy3_45mbrx41goFO1bmmVjE5gsRC5joJYEMeta?.name,
    path: "/deutsch/excursion.php",
    component: component_45stuboAz8_45A0Kn4UDy3_45mbrx41goFO1bmmVjE5gsRC5joJYE
  },
  {
    name: component_45stuboAz8_45A0Kn4UDy3_45mbrx41goFO1bmmVjE5gsRC5joJYEMeta?.name,
    path: "/english/excursion.php",
    component: component_45stuboAz8_45A0Kn4UDy3_45mbrx41goFO1bmmVjE5gsRC5joJYE
  },
  {
    name: component_45stuboAz8_45A0Kn4UDy3_45mbrx41goFO1bmmVjE5gsRC5joJYEMeta?.name,
    path: "/deutsch/news.php",
    component: component_45stuboAz8_45A0Kn4UDy3_45mbrx41goFO1bmmVjE5gsRC5joJYE
  },
  {
    name: component_45stuboAz8_45A0Kn4UDy3_45mbrx41goFO1bmmVjE5gsRC5joJYEMeta?.name,
    path: "/english/news.php",
    component: component_45stuboAz8_45A0Kn4UDy3_45mbrx41goFO1bmmVjE5gsRC5joJYE
  },
  {
    name: component_45stuboAz8_45A0Kn4UDy3_45mbrx41goFO1bmmVjE5gsRC5joJYEMeta?.name,
    path: "/deutsch/sport.php",
    component: component_45stuboAz8_45A0Kn4UDy3_45mbrx41goFO1bmmVjE5gsRC5joJYE
  },
  {
    name: component_45stuboAz8_45A0Kn4UDy3_45mbrx41goFO1bmmVjE5gsRC5joJYEMeta?.name,
    path: "/english/sport.php",
    component: component_45stuboAz8_45A0Kn4UDy3_45mbrx41goFO1bmmVjE5gsRC5joJYE
  },
  {
    name: component_45stuboAz8_45A0Kn4UDy3_45mbrx41goFO1bmmVjE5gsRC5joJYEMeta?.name,
    path: "/deutsch/apartment.php",
    component: component_45stuboAz8_45A0Kn4UDy3_45mbrx41goFO1bmmVjE5gsRC5joJYE
  },
  {
    name: component_45stuboAz8_45A0Kn4UDy3_45mbrx41goFO1bmmVjE5gsRC5joJYEMeta?.name,
    path: "/english/apartment.php",
    component: component_45stuboAz8_45A0Kn4UDy3_45mbrx41goFO1bmmVjE5gsRC5joJYE
  },
  {
    name: component_45stuboAz8_45A0Kn4UDy3_45mbrx41goFO1bmmVjE5gsRC5joJYEMeta?.name,
    path: "/deutsch/pool.php",
    component: component_45stuboAz8_45A0Kn4UDy3_45mbrx41goFO1bmmVjE5gsRC5joJYE
  },
  {
    name: component_45stuboAz8_45A0Kn4UDy3_45mbrx41goFO1bmmVjE5gsRC5joJYEMeta?.name,
    path: "/english/pool.php",
    component: component_45stuboAz8_45A0Kn4UDy3_45mbrx41goFO1bmmVjE5gsRC5joJYE
  },
  {
    name: component_45stuboAz8_45A0Kn4UDy3_45mbrx41goFO1bmmVjE5gsRC5joJYEMeta?.name,
    path: "/deutsch/direction.php",
    component: component_45stuboAz8_45A0Kn4UDy3_45mbrx41goFO1bmmVjE5gsRC5joJYE
  },
  {
    name: component_45stuboAz8_45A0Kn4UDy3_45mbrx41goFO1bmmVjE5gsRC5joJYEMeta?.name,
    path: "/english/direction.php",
    component: component_45stuboAz8_45A0Kn4UDy3_45mbrx41goFO1bmmVjE5gsRC5joJYE
  },
  {
    name: component_45stuboAz8_45A0Kn4UDy3_45mbrx41goFO1bmmVjE5gsRC5joJYEMeta?.name,
    path: "/deutsch/impressum.php",
    component: component_45stuboAz8_45A0Kn4UDy3_45mbrx41goFO1bmmVjE5gsRC5joJYE
  },
  {
    name: component_45stuboAz8_45A0Kn4UDy3_45mbrx41goFO1bmmVjE5gsRC5joJYEMeta?.name,
    path: "/english/impressum.php",
    component: component_45stuboAz8_45A0Kn4UDy3_45mbrx41goFO1bmmVjE5gsRC5joJYE
  },
  {
    name: component_45stuboAz8_45A0Kn4UDy3_45mbrx41goFO1bmmVjE5gsRC5joJYEMeta?.name,
    path: "/italiano/impressum.php",
    component: component_45stuboAz8_45A0Kn4UDy3_45mbrx41goFO1bmmVjE5gsRC5joJYE
  },
  {
    name: component_45stuboAz8_45A0Kn4UDy3_45mbrx41goFO1bmmVjE5gsRC5joJYEMeta?.name,
    path: "/deutsch/press.php",
    component: component_45stuboAz8_45A0Kn4UDy3_45mbrx41goFO1bmmVjE5gsRC5joJYE
  },
  {
    name: component_45stuboAz8_45A0Kn4UDy3_45mbrx41goFO1bmmVjE5gsRC5joJYEMeta?.name,
    path: "/english/press.php",
    component: component_45stuboAz8_45A0Kn4UDy3_45mbrx41goFO1bmmVjE5gsRC5joJYE
  },
  {
    name: component_45stuboAz8_45A0Kn4UDy3_45mbrx41goFO1bmmVjE5gsRC5joJYEMeta?.name,
    path: "/deutsch/gastro.php",
    component: component_45stuboAz8_45A0Kn4UDy3_45mbrx41goFO1bmmVjE5gsRC5joJYE
  },
  {
    name: component_45stuboAz8_45A0Kn4UDy3_45mbrx41goFO1bmmVjE5gsRC5joJYEMeta?.name,
    path: "/english/gastro.php",
    component: component_45stuboAz8_45A0Kn4UDy3_45mbrx41goFO1bmmVjE5gsRC5joJYE
  },
  {
    name: component_45stuboAz8_45A0Kn4UDy3_45mbrx41goFO1bmmVjE5gsRC5joJYEMeta?.name,
    path: "/deutsch/privacy.php",
    component: component_45stuboAz8_45A0Kn4UDy3_45mbrx41goFO1bmmVjE5gsRC5joJYE
  },
  {
    name: component_45stuboAz8_45A0Kn4UDy3_45mbrx41goFO1bmmVjE5gsRC5joJYEMeta?.name,
    path: "/english/privacy.php",
    component: component_45stuboAz8_45A0Kn4UDy3_45mbrx41goFO1bmmVjE5gsRC5joJYE
  },
  {
    name: component_45stuboAz8_45A0Kn4UDy3_45mbrx41goFO1bmmVjE5gsRC5joJYEMeta?.name,
    path: "/italiano/privacy.php",
    component: component_45stuboAz8_45A0Kn4UDy3_45mbrx41goFO1bmmVjE5gsRC5joJYE
  },
  {
    name: component_45stuboAz8_45A0Kn4UDy3_45mbrx41goFO1bmmVjE5gsRC5joJYEMeta?.name,
    path: "/deutsch/agb.php",
    component: component_45stuboAz8_45A0Kn4UDy3_45mbrx41goFO1bmmVjE5gsRC5joJYE
  },
  {
    name: component_45stuboAz8_45A0Kn4UDy3_45mbrx41goFO1bmmVjE5gsRC5joJYEMeta?.name,
    path: "/english/agb.php",
    component: component_45stuboAz8_45A0Kn4UDy3_45mbrx41goFO1bmmVjE5gsRC5joJYE
  },
  {
    name: component_45stuboAz8_45A0Kn4UDy3_45mbrx41goFO1bmmVjE5gsRC5joJYEMeta?.name,
    path: "/italiano/agb.php",
    component: component_45stuboAz8_45A0Kn4UDy3_45mbrx41goFO1bmmVjE5gsRC5joJYE
  },
  {
    name: component_45stuboAz8_45A0Kn4UDy3_45mbrx41goFO1bmmVjE5gsRC5joJYEMeta?.name,
    path: "/deutsch/corona.php",
    component: component_45stuboAz8_45A0Kn4UDy3_45mbrx41goFO1bmmVjE5gsRC5joJYE
  },
  {
    name: component_45stuboAz8_45A0Kn4UDy3_45mbrx41goFO1bmmVjE5gsRC5joJYEMeta?.name,
    path: "/english/corona.php",
    component: component_45stuboAz8_45A0Kn4UDy3_45mbrx41goFO1bmmVjE5gsRC5joJYE
  },
  {
    name: component_45stuboAz8_45A0Kn4UDy3_45mbrx41goFO1bmmVjE5gsRC5joJYEMeta?.name,
    path: "/presse",
    component: component_45stuboAz8_45A0Kn4UDy3_45mbrx41goFO1bmmVjE5gsRC5joJYE
  },
  {
    name: component_45stuboAz8_45A0Kn4UDy3_45mbrx41goFO1bmmVjE5gsRC5joJYEMeta?.name,
    path: "/en/presse",
    component: component_45stuboAz8_45A0Kn4UDy3_45mbrx41goFO1bmmVjE5gsRC5joJYE
  },
  {
    name: component_45stuboAz8_45A0Kn4UDy3_45mbrx41goFO1bmmVjE5gsRC5joJYEMeta?.name,
    path: "/aktuelles",
    component: component_45stuboAz8_45A0Kn4UDy3_45mbrx41goFO1bmmVjE5gsRC5joJYE
  },
  {
    name: component_45stuboAz8_45A0Kn4UDy3_45mbrx41goFO1bmmVjE5gsRC5joJYEMeta?.name,
    path: "/en/aktuelles",
    component: component_45stuboAz8_45A0Kn4UDy3_45mbrx41goFO1bmmVjE5gsRC5joJYE
  },
  {
    name: component_45stuboAz8_45A0Kn4UDy3_45mbrx41goFO1bmmVjE5gsRC5joJYEMeta?.name,
    path: "/casa-bivignano",
    component: component_45stuboAz8_45A0Kn4UDy3_45mbrx41goFO1bmmVjE5gsRC5joJYE
  },
  {
    name: component_45stuboAz8_45A0Kn4UDy3_45mbrx41goFO1bmmVjE5gsRC5joJYEMeta?.name,
    path: "/en/casa-bivignano",
    component: component_45stuboAz8_45A0Kn4UDy3_45mbrx41goFO1bmmVjE5gsRC5joJYE
  },
  {
    name: component_45stuboAz8_45A0Kn4UDy3_45mbrx41goFO1bmmVjE5gsRC5joJYEMeta?.name,
    path: "/angebot/reiten/pferde",
    component: component_45stuboAz8_45A0Kn4UDy3_45mbrx41goFO1bmmVjE5gsRC5joJYE
  },
  {
    name: component_45stuboAz8_45A0Kn4UDy3_45mbrx41goFO1bmmVjE5gsRC5joJYEMeta?.name,
    path: "/en/angebot/reiten/pferde",
    component: component_45stuboAz8_45A0Kn4UDy3_45mbrx41goFO1bmmVjE5gsRC5joJYE
  },
  {
    name: component_45stuboAz8_45A0Kn4UDy3_45mbrx41goFO1bmmVjE5gsRC5joJYEMeta?.name,
    path: "/umgebung",
    component: component_45stuboAz8_45A0Kn4UDy3_45mbrx41goFO1bmmVjE5gsRC5joJYE
  },
  {
    name: component_45stuboAz8_45A0Kn4UDy3_45mbrx41goFO1bmmVjE5gsRC5joJYEMeta?.name,
    path: "/en/umgebung",
    component: component_45stuboAz8_45A0Kn4UDy3_45mbrx41goFO1bmmVjE5gsRC5joJYE
  },
  {
    name: component_45stuboAz8_45A0Kn4UDy3_45mbrx41goFO1bmmVjE5gsRC5joJYEMeta?.name,
    path: "/angebot/appartements",
    component: component_45stuboAz8_45A0Kn4UDy3_45mbrx41goFO1bmmVjE5gsRC5joJYE
  },
  {
    name: component_45stuboAz8_45A0Kn4UDy3_45mbrx41goFO1bmmVjE5gsRC5joJYEMeta?.name,
    path: "/en/angebot/appartements",
    component: component_45stuboAz8_45A0Kn4UDy3_45mbrx41goFO1bmmVjE5gsRC5joJYE
  },
  {
    name: component_45stuboAz8_45A0Kn4UDy3_45mbrx41goFO1bmmVjE5gsRC5joJYEMeta?.name,
    path: "/angebot/gastro",
    component: component_45stuboAz8_45A0Kn4UDy3_45mbrx41goFO1bmmVjE5gsRC5joJYE
  },
  {
    name: component_45stuboAz8_45A0Kn4UDy3_45mbrx41goFO1bmmVjE5gsRC5joJYEMeta?.name,
    path: "/en/angebot/gastro",
    component: component_45stuboAz8_45A0Kn4UDy3_45mbrx41goFO1bmmVjE5gsRC5joJYE
  },
  {
    name: component_45stuboAz8_45A0Kn4UDy3_45mbrx41goFO1bmmVjE5gsRC5joJYEMeta?.name,
    path: "/angebot/appartement/studio",
    component: component_45stuboAz8_45A0Kn4UDy3_45mbrx41goFO1bmmVjE5gsRC5joJYE
  },
  {
    name: component_45stuboAz8_45A0Kn4UDy3_45mbrx41goFO1bmmVjE5gsRC5joJYEMeta?.name,
    path: "/en/angebot/appartement/studio",
    component: component_45stuboAz8_45A0Kn4UDy3_45mbrx41goFO1bmmVjE5gsRC5joJYE
  },
  {
    name: component_45stuboAz8_45A0Kn4UDy3_45mbrx41goFO1bmmVjE5gsRC5joJYEMeta?.name,
    path: "/angebot/appartement/eckzimmer",
    component: component_45stuboAz8_45A0Kn4UDy3_45mbrx41goFO1bmmVjE5gsRC5joJYE
  },
  {
    name: component_45stuboAz8_45A0Kn4UDy3_45mbrx41goFO1bmmVjE5gsRC5joJYEMeta?.name,
    path: "/en/angebot/appartement/eckzimmer",
    component: component_45stuboAz8_45A0Kn4UDy3_45mbrx41goFO1bmmVjE5gsRC5joJYE
  },
  {
    name: component_45stuboAz8_45A0Kn4UDy3_45mbrx41goFO1bmmVjE5gsRC5joJYEMeta?.name,
    path: "/angebot/appartement/hexenhaeuschen",
    component: component_45stuboAz8_45A0Kn4UDy3_45mbrx41goFO1bmmVjE5gsRC5joJYE
  },
  {
    name: component_45stuboAz8_45A0Kn4UDy3_45mbrx41goFO1bmmVjE5gsRC5joJYEMeta?.name,
    path: "/en/angebot/appartement/hexenhaeuschen",
    component: component_45stuboAz8_45A0Kn4UDy3_45mbrx41goFO1bmmVjE5gsRC5joJYE
  },
  {
    name: component_45stuboAz8_45A0Kn4UDy3_45mbrx41goFO1bmmVjE5gsRC5joJYEMeta?.name,
    path: "/angebot/appartement/aprikosenhaeuschen",
    component: component_45stuboAz8_45A0Kn4UDy3_45mbrx41goFO1bmmVjE5gsRC5joJYE
  },
  {
    name: component_45stuboAz8_45A0Kn4UDy3_45mbrx41goFO1bmmVjE5gsRC5joJYEMeta?.name,
    path: "/en/angebot/appartement/aprikosenhaeuschen",
    component: component_45stuboAz8_45A0Kn4UDy3_45mbrx41goFO1bmmVjE5gsRC5joJYE
  },
  {
    name: component_45stuboAz8_45A0Kn4UDy3_45mbrx41goFO1bmmVjE5gsRC5joJYEMeta?.name,
    path: "/angebot/reiten/einfache-reitwochen",
    component: component_45stuboAz8_45A0Kn4UDy3_45mbrx41goFO1bmmVjE5gsRC5joJYE
  },
  {
    name: component_45stuboAz8_45A0Kn4UDy3_45mbrx41goFO1bmmVjE5gsRC5joJYEMeta?.name,
    path: "/en/angebot/reiten/einfache-reitwochen",
    component: component_45stuboAz8_45A0Kn4UDy3_45mbrx41goFO1bmmVjE5gsRC5joJYE
  },
  {
    name: component_45stuboAz8_45A0Kn4UDy3_45mbrx41goFO1bmmVjE5gsRC5joJYEMeta?.name,
    path: "/angebot/reiten/power-reitwoche",
    component: component_45stuboAz8_45A0Kn4UDy3_45mbrx41goFO1bmmVjE5gsRC5joJYE
  },
  {
    name: component_45stuboAz8_45A0Kn4UDy3_45mbrx41goFO1bmmVjE5gsRC5joJYEMeta?.name,
    path: "/en/angebot/reiten/power-reitwoche",
    component: component_45stuboAz8_45A0Kn4UDy3_45mbrx41goFO1bmmVjE5gsRC5joJYE
  },
  {
    name: component_45stuboAz8_45A0Kn4UDy3_45mbrx41goFO1bmmVjE5gsRC5joJYEMeta?.name,
    path: "/angebot/reiten/wanderritte",
    component: component_45stuboAz8_45A0Kn4UDy3_45mbrx41goFO1bmmVjE5gsRC5joJYE
  },
  {
    name: component_45stuboAz8_45A0Kn4UDy3_45mbrx41goFO1bmmVjE5gsRC5joJYEMeta?.name,
    path: "/en/angebot/reiten/wanderritte",
    component: component_45stuboAz8_45A0Kn4UDy3_45mbrx41goFO1bmmVjE5gsRC5joJYE
  },
  {
    name: component_45stuboAz8_45A0Kn4UDy3_45mbrx41goFO1bmmVjE5gsRC5joJYEMeta?.name,
    path: "/angebot/reiten/yoga-reitwoche",
    component: component_45stuboAz8_45A0Kn4UDy3_45mbrx41goFO1bmmVjE5gsRC5joJYE
  },
  {
    name: component_45stuboAz8_45A0Kn4UDy3_45mbrx41goFO1bmmVjE5gsRC5joJYEMeta?.name,
    path: "/en/angebot/reiten/yoga-reitwoche",
    component: component_45stuboAz8_45A0Kn4UDy3_45mbrx41goFO1bmmVjE5gsRC5joJYE
  },
  {
    name: component_45stuboAz8_45A0Kn4UDy3_45mbrx41goFO1bmmVjE5gsRC5joJYEMeta?.name,
    path: "/sitemap.xml",
    component: component_45stuboAz8_45A0Kn4UDy3_45mbrx41goFO1bmmVjE5gsRC5joJYE
  }
]